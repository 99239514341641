<template>
  <div class="setting-page padding-left-layout padding-top-layout padding-right-layout">
    <page-title :title="textTitle" />
    <div class="settings-btn">
      <router-link class="setting-btn" v-for="(item, index) in items" :key="index" :to="item.path">
        <div class="setting-btn-text">
          <div class="setting-btn-img">	
            <img :src="getSettingIcon(item.image)" alt="" />	
          </div>
          <div class="setting-text">{{ item.text }}</div>
        </div>
        <div class="arrow-svg"><img src="@/assets/images/setting/arrow.svg" alt="" /></div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import {ROLE} from '@/constants/role';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
export default {
  name: 'Setting',
  components: {
    PageTitle,
  },
  data() {
    return {
      breadcrum: [
        {
          text: this.$t('setting.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
        {
          text: this.$t('setting.label_setting'),
          disabled: true,
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING}`,
        },
      ],
      isHidden: false,
      items: [
        { key: 1, text: this.$t('setting.button_user_management'), path: ROUTES.PRODUCTS_EMISSION + ROUTES.USER_MANAGEMENT, image: 'user.svg' },
        { key: 2, text: this.$t('setting.button_facility_management'), path: ROUTES.PRODUCTS_EMISSION + ROUTES.FACILITY_MANAGEMENT, image: 'facility.svg' },
        { key: 3, text: this.$t('setting.button_management_viewer'), path: ROUTES.PRODUCTS_EMISSION + ROUTES.VIEWER_MANAGEMENT, image: 'viewer.svg' },
        { key: 4, text: this.$t('setting.button_fiscal_year'), path: ROUTES.PRODUCTS_EMISSION + ROUTES.CALCULATION_TERM, image: 'calculation.svg' },
        {
          key: 5,
          text: this.$t('setting.button_db_customize'),
          path: ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE,
          image: 'db-customize.svg',
        },
        {
          key: 7,
          text: this.$t('setting.button_setting_auto_approval'),
          path: ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING_AUTO_APPROVAL,
          image: 'auto-approval.svg',
        },
        {
          key: 8,
          text: this.$t('setting.button_setting_language'),
          path: ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING_LANGUAGE,
          image: 'language.svg',
        },
        {
          key: 9,
          text: this.$t('setting.button_change_pass'),
          path: ROUTES.PRODUCTS_EMISSION + ROUTES.CHANGE_PASSWORD_USER,
          image: 'change-pass.svg',
        },
        // {
        //   key: 10,
        //   text: this.$t('linked_service_management.button_linked_service_management_setting'),
        //   path: ROUTES.PRODUCTS + ROUTES.LINKED_SERVICE_MANAGEMENT,
        //   image: 'linked_service_management.svg',
        // },
        {
          key: 11,
          text: this.$t('setting.button_setting_csv_format'),
          path: ROUTES.PRODUCTS_EMISSION + ROUTES.CSV_FORMAT,
          image: 'csv-format.svg',
        },
      ],
    };
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
    this.updateItems();
  },
  computed: {
    ...mapGetters(['getRoles']),
    ...mapState('userData', ['currentUser']),
    textTitle() {
      return this.$t('setting.title_setting')
    }
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    getSettingIcon(image) {
      return require(`@/assets/images/setting/${image}`);
    },
    redirectPage(url) {
      return this.$router.push({ path: url });
    },
    updateItems() { 
      const curentRole = this.currentUser.user.role_id
      if (curentRole === ROLE.APPROVAL || curentRole === ROLE.USER) {
        const listkeyByrole = curentRole === ROLE.APPROVAL ? [5, 7, 8, 9, 11] : [5, 8, 9, 11]
        const items = this.items.filter((item) => listkeyByrole.includes(item.key));
        this.items = items;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.setting-page {
  .settings-btn {
    display: flex;
    flex-flow: column;
    .setting-btn {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $monoOffWhite;
      cursor: pointer;
      box-shadow: 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 8px 8px 8px 12px;
      .setting-btn-text {
        display: flex;
        align-items: center;
        gap: 12px;
        img {
          flex-shrink: 0;
        }
        div {
          padding-left: 12px;
          color: $monoBlack;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          text-align: center;
        }
        .setting-btn-img {
            display: flex;
            width: 48px;
            height: 48px;
            padding: 6.5px 8.5px 7.54px 7.5px;
            justify-content: center;
            align-items: center;
          }
      }
      &:hover {
        background-color: $monoWhite;
      }
      &:active {
        border: 2px solid $blueMid;
      }
    }
    .arrow-svg {
      width: 36px;
      height: 48px;
    }
  }
  .page-title {
    margin-bottom: 80px;
  }
}

@include desktop {
  .setting-page {
    .settings-btn {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      padding-top: 32px;
      gap: 24px;
      .setting-btn {
        width: 197px;
        height: 136px;
        margin: 0px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: unset;
        &:hover {
          cursor: pointer;
        }
        .arrow-svg {
          display: none;
        }
        .setting-btn-text {
          display: flex;
          flex-flow: column;
          gap: 0px;
          padding: 26px 10px 22px;
          .setting-text {
            padding-left: 0;
            margin-top: 16px;
          }
        }
        // &:nth-child(odd) {
        //   margin-left: unset;
        // }
        // &:nth-child(even) {
        //   margin-right: unset;
        // }
      }
    }
  }
}
</style>
<style lang="scss">
#adminSetting {
  // height: 584px;
}
@include desktop {
  #adminSetting {
    height: unset;
  }
}
</style>
